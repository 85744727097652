@include media-breakpoint-down(sm) {  }
@include media-breakpoint-down(md) {  }
@include media-breakpoint-down(lg) {
  #loader .loader-logo img {
    width: 50vw !important;
  }
  div#body{
    padding-top: 140px !important;
  }
  header{
    .logo{
      transition: height 0.3s;
      height: 131px;
    }
  }
  header.shrink{
    height: 70px !important;
    padding-top: 10px !important;
    #nav li a{
      padding: 0px !important ;
    }
    .menu-toggle{
      height: 42px;
      right: 20px;
      top: 4px;
    }
    img.logo {
      width: 60px;
      height: 54px;
    }
  }
  .mob-x{
    width: 32px;
    height: 32px;
    position: absolute;
    top: 30px;
    right: 20px;
    background-image: url(../img/mobile-x.svg);
    background-size: contain;
    background-position: center;
    display: none;
  }
  .faqs{
    .card-body{
      font-size: 23px !important;
      line-height: 38px !important;
      text-align: left !important;
    }
  }
  button.btn.btn-link {
    white-space: normal;
    text-align: left;
    padding-right: 60px !important;
  }
  .menu--mobile{
    nav.open{
      .mob-x{
        display: block;
        z-index: 999;
      }
    }

  }
  .hero{
    &:before{
      padding-top: 129% !important;
    }
  }

}
@include media-breakpoint-down(xl) {  }