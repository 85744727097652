.faqs {
  .card {
    background-color: transparent;
    border: 0px solid transparent;
    border-top: 3px solid $secondary;
    border-bottom: 3px solid $secondary;
    margin-bottom: 27px;
    border-radius: 0px;
    .card-header {
      font-family: $font-family-block;
      text-align: center;
      font-size: 28px;
      line-height: 36px;
      background-color: transparent;
      border: 0px solid transparent;
      padding: 0px;
      .btn-link:hover{
        text-decoration: none;
      }
      h5{
        width: 100%;
        position: relative;

        button{
          width: 100%;
          padding: .75rem 1.25rem;
          position: relative;
          font-size: 28px;
          &.collapsed{
            .faq-arrow{
              transform: translate3d(0,-50%,0) rotate3d(0,0,1,0deg);
            }
          }
          .faq-arrow{
            position: absolute;
            right: 0;
            top: 50%;
            transform-origin: 50% 50%;
            transform: translate3d(0,-50%,0) rotate3d(0,0,1,90deg);
            transition: transform 0.3s;
            svg {
              width: 50px;
              height: 50px;
            }
          }
          &:focus{
            text-decoration: none;
          }
        }

      }
    }
    .card-body {
      color: #1F2326;
      font-family: Jubilat, sans-serif;
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 21px;
      text-align: center;
      margin: auto;
      max-width: 730px;
    }
  }
}