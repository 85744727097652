@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import "mixins/aspect-ratio";
@import "custom-css";
@import "faqs";
@import "mobile";
//FUNCTIONAL
@import "animate";

html{
  overflow-x:hidden;
}
div#body {
  //Compensate for fixed menu
  padding-top: 100px;
}
body {
  border: 20px solid #FFF;
  overflow-x:hidden;
  font-family: 'jubilat';
  background-image: url("../img/wooden_panels.jpg");
  background-position: center;
  background-size: contain;
  padding-top:20px;
  @media(max-width:575.98px){
    border-width:10px;
  }
}

p {
  color: #1F2326;
  font-size: 18px;
  line-height: 35px;
  font-weight:300;
  @media(max-width:575.98px){
    font-size:16px;
  }
}

.parallax-mirror{
  margin: 1px 0px;
}

header {
  background: #FFF;
  z-index:100;
  position:fixed;
  top:0;
  left:0;
  right:0;
  padding-top:20px;
  transition: height 0.3s, padding 0.3s;
  height: 175px;
  #nav {
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
    li {
      font-family: 'FrontageCondensed-Regular';

      &.nav-logo {
        padding: 0 40px 15px;
        transition: height 0.3s;
        height: 145px;
        img{
          transition: height 0.3s;
          height:130px;
        }
        a{

          transition: padding 0.3s, border-radius 0.3s, background-color 0.3s;
        }
      }
      &:hover{
        a{
          color: $secondary;
        }
      }
      a {
        padding: 0 40px 10px;
        display: block;
        color: #354B54;
        text-decoration: none;
        font-size: 20px;
        transition: ease 0.3s;
        @media(max-width:575.98px){
          padding:0;
        }
      }

    }
  }
  svg{
    width: 113px;
    transition: width 0.3s, height 0.3s;
  }
  &.shrink{
    height: 50px;
    padding-top: 10px;
    #nav{
      li{
        &:hover{
          a{
            color: $secondary;
            border-bottom: 3px solid $secondary;
          }
        }
        a{
          position: relative;
          border-bottom: 3px solid transparent;
          padding:0 20px 10px;
        }
      }

      
    }
    .nav-logo{
      position: relative;
      height: 40px!important;
      a{
        overflow: hidden;
        display: block;
        background-color: #fff;
        padding: 10px 20px 0px;
        border-radius: 40px;
      }
      img{
        margin: auto;
      }
      svg{
        width: 70px;
        height: 70px;
        path,polygon{
          fill: transparent;
        }
        #brown-swirl{
          fill: #956C6C;
        }
        #pink-swirl{
          fill:#E68CB9;
        }
        #green-swirl{
          fill: #A0CB84;
        }
        #yellow-swirl{
          fill: #FFDD77;
        }
      }
    }

    .menu-toggle{
      height:43px;
      width:21px;
    }

    @media(max-width: 991.98px){
      height:70px;
    }
    .menu{

      .social-media{
        top:-2px;
  
        li{
  
          a{
            height:35px;
            width:35px;
            font-size:20px;
          }
        }
      }
    }
  }
}

.menu{

  .social-media{
    position:absolute;
    top:10px;
    right:0;

    li{
      padding:0 8px;
      a{
        height:40px;
        width:40px;
        font-size:20px;

        &::before{
          width:55px;
          height:55px;
        }
        &::after{
          width:70px;
          height:70px;
          border-width:3px;
        }
      }
    }
  }

  &-toggle{
    transition:ease 0.3s;
    height:72px;
    width:35px;
    background-image:url("../img/menu-toggle.svg");
    background-size: contain;
    background-position:center;
    background-repeat:no-repeat;
    position:absolute;
    top:0px;
    right: 20px;
  }
  &--mobile{
    padding-bottom:20px;
    justify-content:center;
    .nav-close{
      position:absolute;
      top:30px;
      right:30px;
      z-index:100;
    }
    nav{
      position:fixed;
      display:flex;
      align-items:center;
      z-index:100;
      top:100%;
      height:100%;
      right:0;
      left:0;
      transition:ease 0.3s;
      background-image:url('../img/wooden_panels.jpg');
      background-size:cover;
      background-position: center;
      flex-direction: column;
      justify-content: space-around;
      &.open{
        top:0;
      }

      .nav-logo{
        display:none;
      }

      .nav{
        li{
          flex-basis:100%;
          padding:0px 10px 10px !important;
          text-align:center;
          
          a{
            font-size:30px!important;
          }
        }
      }
      .social-media{
        margin-bottom: 30px;
        li{
          padding:0 7px;
          a{
            width:40px;
            height:40px;
            font-size:20px;
          }
        }
      }

    }
  }
}
.hero {
  color: #FFF;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  @include aspect-ratio(1546,909);
  min-height:80vh;
  .parallax {
    will-change: filter, transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
  .container{
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    z-index:20;
    width: 100%;
    height: 100%;

    @media(min-width:1400px){
      max-width:1400px!important;
    }
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #03172C 100%);
    z-index: 10;
    opacity: 0.6;
  }
  *{
    position: relative;
    z-index: 10;
  }
  h1 {
    font-family: $font-family-header;
    font-size: 68px;
    font-weight: 600;
    line-height: 65px;
    color: #fff;
    letter-spacing: 2px;
    margin-bottom:20px;
    span {
      font-size: 34px;
      font-weight: 500;
      display:block;
      margin-top:-10px;
    }
  }
  p {
    color: #FFF;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0.18px;
    margin-bottom: 30px;
  }
  &--dark-text {
    color: #222;
    h1{
      color:#222;
    }
    p {
      color: #222;
    }
  }
  &__cta {
    margin: 0 25px;
    color: #FFF !important;
    text-decoration: none !important;
    font-family: $font-family-block-lite;
    text-transform: uppercase;
    letter-spacing: 0.19px;
    font-size: 25px;
    transition: colour 0.3s;
    img{
      transform-origin: 50%;
      transition: transform 0.5s;
    }
    &:hover{
      color: $secondary;
      img{
        transform: rotate3d(0,0,1,360deg);
      }
    }
    &-container {
      display: flex;
      justify-content: flex-end;

    }

    &:last-of-type {
      margin-right: 0;
    }
    @media(max-width:575.98px){
      font-size:21px;
      white-space:nowrap;
      margin:0 10px;
    }
  }
  > .container {
    height: 100%;
    > .row {
      height: 100%;
    }
  }
  .hero-title{
    animation: hero_title 0.5s ease-out forwards;
    transform: translate3d(0,50px,0);
    opacity: 0;
  }
  @keyframes hero_title{
    0%{
      transform: translate3d(0,50px,0);
      opacity: 0;
    }

    100%{
      transform: translate3d(0,0px,0);
      opacity: 1;
    }
  }
  @media(max-width:575.98px){
    h1{
      font-size:44px;
      line-height:40px;
      margin-bottom:10px;
      span{
        font-size:22px;
      }
    }
    p{
      font-size:18px;
      line-height:1.6em;
      br{
        display:none;
      }
    }
  }
}

.content {
  background-size: cover;

  h2 {
    margin-bottom: 30px;
    font-size: 45px;
    font-weight: 500;
    letter-spacing: 0.29px;
    @media(max-width:575.98px){
      font-size:32px;
    }
  }
  h4{
    font-family: 'FrontageCondensed-Regular';
    font-size: 28px;
    letter-spacing: -2px;
    font-weight:600;
    margin-bottom:-24px;
  }
  p {
    font-weight: 300;

  }
  .content-left-image, .content-right-image {
    position: absolute;
    z-index:10;
    @media(max-width:991.98px){
      transform:none!important;
      max-width:120px!important;
    }
  }

  .content-left-image{
    @media(max-width:991.98px){
      left:0;
    }
  }
  .content-right-image{
    @media(max-width:991.98px){
     right:0;
    }
  }

  @media(max-width:991.98px){
    padding:50px 0!important;
  }
}

main {
  padding: 100px 0;
  p {
    margin-bottom: 30px;
  }
}

img.aligncenter {
  display: block;
  margin: 30px auto;
}

.link-blocks {
  position:relative;
  overflow-y:hidden;
  &__item {
    font-family: 'FrontageCondensed-Regular';
    @include aspect-ratio(500, 300);
    background-size: 100% 100%;
    background-position: center;
    background-repeat:no-repeat;
    border: 3px solid #FFF;
    background-color:#FFF;
    overflow:hidden;
    transition:ease 0.3s;
    @media(max-width:575.98px){
      background-size:cover;
      @include aspect-ratio(500,500);
    }
    &--double {
      @include aspect-ratio(500, 606);
      @media(max-width:575.98px){
        @include aspect-ratio(500, 750);
      }
    }
    &--light {
      * {
        color: #FFF !important;
      }
      path{
        fill:#FFF;
      }
    }
    &--background{
      &-center{
        background-position:center;
      }
      &-top{
        background-position:top;
      }
      &-right{
        background-position:right;
      }
      &-bottom{
        background-position:bottom;
      }
      &-left{
        background-position:left;
      }
    }
    &--top {

      .info {
        top: 15px;
      }
    }

    &--bottom {

      .info {
        bottom: 15px;
      }
    }

    .info {
      position: absolute;
      
      right: 15px;

      h3{
        font-size: 32px;
        letter-spacing: -1.82px;
        font-weight:600;
        text-align:right;
        
        @media(max-width:575.98px){
          font-size:24px;
        }
      }
      h5{
        display:inline-block;
        @media(max-width:575.98px){
          font-size:16px;
        }
      }
      svg{
        margin-right:15px;
      }

      &-tagline{
        transform:translateX(calc(100% - 25px));
        transition:ease 0.3s;

        @media(max-width:575.98px){
          transition:none;
          transform:none;
          text-align:right;
        }
      }
    }
    a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index:20;
    }
    &:hover, &:active{

        background-size:120% 120%;
        & .link-blocks__title-block{
          transform:translateY(0);
        }
        .info-tagline.has-content{
          transform:translateX(0%);
        }
 
    }
    path{
      mask:none!important;
    }
  }

  &__title-block{
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    background-image:url(../img/flavour-hover.jpg);
    background-size:cover;
    background-repeat:no-repeat; 
    top:0;
    right:0;
    bottom:0;
    left:0;
    transform:translateY(100%);
    transition:ease 0.3s;

    h3{
      position:static;
      color:#FFF;
      font-size:60px;
      font-weight:600;
      letter-spacing:-5px;
      text-align:center;
      max-width:300px;

      @media (min-width: 1200px) and (max-width:1400px){
        font-size:40px;
      }
      @media(max-width:575.98px){
        font-size:38px;
      }
    }
    a{
      position:static;
    }
  }
  &__expand{
    position:absolute;
    top:15px;
    right:15px;
    width:80px;
    height:80px;

    @media(max-width:575.98px){
      width:50px;
      height:50px;
    }
  }

  .video-button{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    font-size:5rem;
  }
  .modal{

    &-dialog{
      max-width:1800px;
      width:calc(100% - 40px);
    }
  }
}

.flavours-details{
  position:absolute;
  top:100%;
  left:0;
  right:0;
  bottom:0;
  transition:ease 0.7s;
  display:flex;
  align-items:stretch;
  flex-wrap:wrap;
  &__content{
    padding:60px 30px;
    width:100%;
    background-image: url('../img/wooden_panels.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    display:flex;
    align-items:center;
    &__icon{
      max-height:125px;
      margin-bottom:60px;
    }
    > div{
      max-width:850px;
      margin:0 auto;
    }
  }
  .parallax-img{
    position:relative;
    width:100%;

    @media (max-width: 1800px){
      min-height:60vh!important;
    }
    
    @media (max-width: 1400px){
      min-height:40vh!important;
    }
  }
  &__back{
    position:absolute;
    background-image:url('../img/flavour-hover.jpg');
    background-size:cover;
    background-position:center;
    bottom:0;
    left:0;
    width:120px;
    height:120px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;

    img{
      width:80px;
      height:80px;
    }
  }
  &.open{
    top:0;
    z-index:100;
  }

  @media (max-width: 1199.98px){
    position:fixed;
    overflow-y:scroll;
  }
}

.parallax-img {
  background-position: center;
  background-size: cover;
  border-top: 20px solid #FFF;
  border-bottom: 20px solid #FFF;
  @media(max-width:575.98px){
    height:400px!important;
  }
}

.gallery-six {
  position: relative;
  &:before {
    content: '';
    z-index: 10;
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 2px solid #FFF;
    @media(max-width:575.98px){
      display:none;
    }
  }
  &__logo {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {

    }
    h5 {
      font-family: 'FrontageCondensed-Regular';
      color: #FFF;
      font-size: 18px;
      letter-spacing: 1px;
      white-space:nowrap;
    }
    @media(max-width:575.98px){
      display:none;
    }
  }
  img{
    width:100%;

    @media(max-width:575.98px){
      border-bottom:20px solid white;
    }
  }
}

.image-and-text {
  padding: 60px;
  background-size: cover;
  background-position: center;

  img {

  }
  &__content {
    max-width: 650px;
    h2 {
      font-size: 70px;
      line-height: 1em;
      font-weight: 600;
      margin-bottom: 36px;
    }
    
    @media(max-width:991.98px){
      margin-top:50px;

      h2{
        font-size:50px;

        br{
          display:none;
        }
      }
    }
  }
  @media(max-width:991.98px){
    padding:30px;

    .image-container{
      order:1;
    }
    .text-container{
      order:2;
    }
  }

}

.blog-feed {
  background: #FFF;
  padding: 80px 0;
  &__post {
    font-family: 'FrontageCondensed-Regular';
    margin-bottom:30px;
    &-link {
      position: absolute;
      left: 15px;
      top: 0;
      right: 15px;
      bottom: 0;
    }
    &-img {
      position: relative;
      background-size: cover;
      background-position: center;
      @include aspect-ratio(280, 155);
    }
    &-date {
      position: absolute;
      top: 10px;
      right: 0;
      background: #FFF;
      font-family: 'FrontageCondensed-Regular';
      font-size: 14px;
      letter-spacing: 1px;
      padding: 0 10px;
    }
    &-content {
      background-image: url('../img/wooden_panels.jpg');
      background-size: cover;
      background-position: center;
      text-align: right;
      padding: 30px 15px 15px;
      h3 {
        font-family: 'FrontageCondensed-Regular';
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        font-family: 'FrontageCondensed-Regular';
        line-height: 1.6em;
        font-size: 16px;
        margin: 0;
      }
    }
  }
  aside {

    .newsletter-heading {
      background-image: url('../img/newsletter-bg.jpg');
      background-size: cover;
      background-position: center;
      font-family: 'FrontageCondensed-3D';
      font-size: 32px;
      text-align: center;
      padding: 30px;

      @media(max-width:1199.98px){
        max-width:500px;
        margin:0 auto;
      }
    }
    .newsletter-signup {
      @include aspect-ratio(380, 560);
      background-image: url('../img/wooden-sign.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      top: -18px;
      &__fields {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: 55%;
        text-align: center;

        input {
          border: none;
          outline: none !important;
          background: transparent;
          color: #FFF;
          text-align: center;
          border-bottom: 2px dashed #FFF;
          max-width: 60%;
          font-size: 24px;
          margin-left:45px;
          &[type="text"] {
            margin-bottom: 75px;
            @media(max-width:2000px){
              margin-bottom:60px;
            }
            @media(max-width:1600px){
              margin-bottom:40px;
            }
            @media(max-width:1400px){
              margin-bottom:20px;
            }

            @media(max-width:1199.98px){
              margin-bottom:85px;
            }
            @media(max-width:500px){
              margin-bottom:55px;
            }
            @media(max-width:375px){
              margin-bottom:40px;
            }
          }
          &[type="email"] {
            margin-bottom: 105px;
            @media(max-width:2000px){
              margin-bottom:90px;
            }
            @media(max-width:1800px){
              margin-bottom:65px;
            }
            @media(max-width:1600px){
              margin-bottom:50px;
            }
            @media(max-width:1400px){
              margin-bottom:40px;
            }

            @media(max-width:1199.98px){
              margin-bottom:115px;
            }
            @media(max-width:500px){
              margin-bottom:70px;
            }
            @media(max-width:375px){
              margin-bottom:60px;
            }
          }
          &[type="submit"] {
            border: 2px solid #FFF;
            display: block;
            margin: 0 auto;
            font-family: 'FrontageCondensed-Regular';
            transform: rotate(-5deg);
            font-size: 28px;
            letter-spacing: -2px;
            padding: 0 30px;
          }
          &::placeholder {
            color: #FFF;
          }
        }
        @media(max-width:1199.98px){
          padding-top:57%;
        }
      }
      @media(max-width:1199.98px){
        max-width:500px;
        margin:0 auto;
      }
    }
  }
}
.lazy {
  opacity: 0;
  transform: translate3d(0,40px,0);

  &.delay{

    &-1{
      transition-delay:0.25s;
    }
    &-2{
      transition-delay:0.5s;
    }
    &-3{
      transition-delay:1s;
    }
    &-4{
      transition-delay:1.25s;
    }
  }
}

.fadein {
  opacity: 1;
  transform: translate3d(0,0px,0);
  transition: opacity 0.8s, transform 0.8s;
}
.post-extras {
  background: #FFF;
  padding: 100px 0;

  &__share {
    margin-bottom: 60px;
    h4 {
      text-align: center;
      font-family: 'FrontageCondensed-Regular';
      font-size: 28px;
      margin-bottom: 30px;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        padding: 0 15px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 50px;
          border: 1px solid #000;
          border-radius: 50%;
        }
      }
    }
  }
  &__related {
    h2 {
      text-align: center;
      font-size: 40px;
    }
  }
}

.newsletter {
  background: #F9F0B3;
  color: #333;
  text-align: center;
  padding: 50px 0;

  h3 {
    font-family: 'FrontageCondensed-3D';
    margin: 0;
    font-size: 40px;
    margin-bottom: 5px;
    @media(max-width:575.98px){
      font-size:34px;
    }
  }
  h5 {
    font-family: 'FrontageCondensed-Regular';
    font-size: 16px;
    margin-bottom: 35px;
  }
  form{
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      &[type="text"] {
        padding: 10px;
        width: 100%;
        max-width: 472px;
      }
      @media (max-width: 575.98px){
        margin-bottom:30px;
      }
    }
    button {
      background-color: transparent;
      color: #000;
      font-size: 28px;
      letter-spacing: -1.82px;
      text-transform: uppercase;
      font-family: $font-family-block-lite;
      border: 2px solid #fff;
      margin-left: 20px;
      font-weight: 900;
      line-height: 40px;
      padding-bottom: 4px;
      cursor: pointer;
    }

    @media (max-width: 575.98px){
      display:block;
      padding:0 30px;
    }
  }
}
.stockist-form{
  padding: 36px 30px;
  h3{
    text-align: center;
    font-family: $font-family-block;
    font-size: 40px;
    color: #fff;
    margin-bottom: 39px;
  }
  input{
    border-radius: 0px;
    height: 44px;
  }
  .intl-tel-input {
    width: 100%;
  }
  .form-next-arrow{
    background-image: url(../images/arrow-faq.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    input{
      background-color: transparent;
      color: transparent;

      @media(max-width:575.98px){
        width:100%;
      }
    }
  }
}

.testimonials{
  padding-top: 115px;
  padding-bottom:80px;
  .slick{
    .slick-list{}
  }
  .slick-dots{
    width: 100%;
    display: block;
    flex-basis: 100%;
  }
  .slick-arrow{
    position:absolute;
    top:30%;
    transform:translateY(-50%);
    width:68px;
    height:68px;
    z-index:50;
    outline:none;
    color:transparent;
    border:none;
    background-color:transparent;
    &.slick-prev{
      left:0;
      background-image:url('../img/testimonials/left-arrow.svg')
    }
    &.slick-next{
      right:0;
      background-image:url('../img/testimonials/right-arrow.svg')
    }
    @media (max-width: 991.98px){
      display:none!important;
    }
  }
  .testimonial-van{
    display: block;
    margin: auto;
    padding-bottom: 35px;
  }
  .slick-dots{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    li{
      &.slick-active{
        button{
          color: transparent;
          border: 2px solid $secondary;
          background-color: $secondary;
        }
      }
    }
    button{
      color: transparent;
      border: 2px solid $secondary;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      display: block;
      background-color: transparent;
      margin: 10px;
      line-height: 0px;
      cursor: pointer;
    }

  }
  .testimonial{
    text-align: center;
    padding: 0 100px;
    p{
      color: $secondary;
      font-size: 34px;
      font-weight: 500;
      letter-spacing: 0.22px;
      line-height: 46px;

    }

    &-quotes{
      display:inline-block;
      &--open{
        margin-right:20px;
      }
      &--close{
        margin-left:20px;
      }
    }
    .attribution{
      font-size: 17px;
      letter-spacing: 0.13px;
      line-height: 21px;
      color: $secondary;
      padding-top: 20px;
      padding-bottom:30px;
    }
    @media (max-width: 991.98px){
      padding:0;

      p{
        font-size:16px;
        line-height:32px;
      }
      &-quotes{
        width:25px;
        
        &--open{
          margin-right:10px;
        }
        &--close{
          margin-left:10px;
        }
      }
    }
  }
}

.contact{
  border-top:20px solid white;
  border-bottom:20px solid white;
  overflow:hidden;
  &__details{
    @include aspect-ratio(500, 500);
    float:left;
    width:50%;
    background-image:url('../img/address-box.jpg');
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    color:#FFF;
    font-size:30px;
    font-weight:600;
    >.content{
      left:50px;
      display:flex;
      align-items:center;
      @media(max-width:575.98px){
        left:30px; 
      }
    }
    img{
      margin-bottom:30px;
      @media(max-width:575.98px){
        width:30px;
        margin-bottom:20px;
      }
    }
    address{
      margin-bottom:40px;
      @media(max-width:575.98px){
       margin-bottom:0; 
      }
    }
    p{
      color:#FFF;
      font-size:30px;
      font-weight:600;
      @media(max-width:575.98px){
        font-size:17px;
        line-height:1.1em;
      }
    }
    h5{
      color:#FFF;
      font-size:30px;
      font-weight:600;
      @media(max-width:575.98px){
        font-size:20px; 
      }
    }
    @media (max-width: 1199.98px) {
      width:100%;
    }
    @media(max-width:575.98px){
      font-size:20px;   
    }
  }

  &__map{
    width:50%;
    float:left;
    @media (max-width: 1199.98px) {
      width:100%;
    }
  }
}
footer {
  background-image: url("../img/wooden_panels.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 60px 0 30px;


  #footer-nav {
    ul {
      align-items: center;
      margin: auto;
      justify-content: center;
      margin-top: 30px;
      li {
        a {
          font-size: 20px;
          text-transform: uppercase;
          font-family: $font-family-block-lite;
          padding: 20px 33px;

          @media (max-width: 575.98px){
            padding:20px 10px;
            font-size:18px;
          }
        }
      }
    }
  }
  .small-print{
    font-size: 14px;
    color: #9A9A96;
    padding: 20px;
    a{
      color: #9A9A96;

    }
  }
  .sts-container{

    @media(max-width:575.98px){
      width:100%;
    }
  }
  .sts-logo{
    max-width:55px;
    margin-top:-50px;
    @media(max-width:575.98px){
     margin-top:50px;
    }
  }
  .footer-logo{
      margin-top:15px;
  }

  .footer-info{

    @media(max-width:1199.98px){
      display:flex;
      flex-wrap:wrap-reverse;

      .pull-right{
        width:100%;
      }
    }
  }
}
.social-media {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  li {
    padding: 0 15px;

    a {
      text-decoration:none;
      position:relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border: 1px solid #000;
      border-radius: 50%;
      font-size: 30px;
      transition:ease 0.3s;
      background:none!important;
      &:hover, &:active{
        &::before, &::after{
          opacity: 1;
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
        &::after{

        }
      }
      &::before, &::after{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75px;
        height: 75px;
        border: 2px solid $secondary;
        border-radius: 50%;
        content: '';
        opacity: 0;
        transition: transform 0.5s, opacity 0.5s;
        transform: translateX(-50%) translateY(-50%) scale(0.2);
      }
      &::after{
        width: 90px;
        height: 90px;
        border-width: 4px;
        transform: translateX(-50%) translateY(-50%) scale(0.8);
      }
    }
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

@media(min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media(min-width: 1800px) {
  .container {
    max-width: 1740px;
  }
}

@media(min-width: 2000px) {
  .container {
    max-width: 1940px;
  }
}





//Morris Additions
#loader {
  position: fixed;
  background-color: #FFF;
  background-image: url("../img/wooden_panels.jpg");
  background-position: center;
  background-size: contain;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: loading 0.5s linear forwards;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-logo {
    img {
      width: 10vw;
    }
  }
  &.leaving {
    animation: leaving 0.2s linear forwards;
  }
}

@keyframes loading {
  0% {
    opacity: 1;
    z-index: 999;
  }
  50% {
    opacity: 0.5;
    z-index: 999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes leaving {
  0% {
    opacity: 0;
    z-index: 999;
  }
  50% {
    opacity: 0.5;
    z-index: 999;
  }
  100% {
    opacity: 1;
    z-index: 999;
  }
}

.map-responsive{
  overflow:hidden;
  padding-bottom:100%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}